var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 px-0 mb-3"},[_c('ValidationProvider',{staticClass:"form__group field",attrs:{"tag":"div","name":"Titulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{staticClass:"form__field",class:classes,attrs:{"type":"input","placeholder":"Titulo","name":"Titulo","id":"Titulo"},domProps:{"value":_vm.title},on:{"input":function($event){return _vm.$emit('update:title',$event.target.value)}}}),_c('label',{staticClass:"form__label",class:classes,attrs:{"for":"Titulo"}},[_vm._v("Titulo")])]}}])})],1),_c('ValidationProvider',{staticClass:"col-md-12 px-0 mb-3",attrs:{"tag":"div","rules":'required|max:800',"name":"Descricao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default",class:classes},[_vm._v("Comunicado")]),_c('textarea',{staticClass:"w-100 px-2 py-2",class:classes,attrs:{"name":"Descricao"},domProps:{"value":_vm.description},on:{"input":function($event){return _vm.$emit('update:description',$event.target.value)}}}),_c('span',_vm._b({staticClass:"contador",class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(_vm.description.length)+"/ 800")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }