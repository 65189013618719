<template>
  <div class="boxs">
    <div class="col-md-10 m-auto">
      <div class="row">
        <div class="col-md-3 text-center"><h2>Lido</h2></div>
        <div class="col-md-3 text-center"><h2>Titulo</h2></div>
        <div class="col-md-3 text-center"><h2>Data de Inicio</h2></div>
        <div class="col-md-3 text-center"><h2>Detalhes</h2></div>
      </div>
    </div>

    <div class="containerfather">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="index === 1 ? 'color' : ''"
      >
        <div class="col-md-10 m-auto containerChildre">
          <div class="row rows">
            <div class="col-md-3 text-center">
              <input disabled type="checkbox" name="" class="checks" />
              <img
                v-if="item.read"
                class="checkd"
                src="@/assets/image/icones/checked.png"
                alt="check"
              />
            </div>
            <div class="col-md-3 text-center">
              <h2>{{ item.notice.title }}</h2>
            </div>
            <div class="col-md-3 text-center">
              <h2>{{ item.notice.dateInitial.substring(0, 10) }}</h2>
            </div>
            <div class="col-md-3 text-center">
              <button
                @click="$emit('modalImg', item.notice.image, item.notice.id, item.notice.link)"
                class="buttonImg"
              >
                <img src="@/assets/image/icones/image.png" alt="image" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      require: true,
    },
    status: {
      type: String,
      require: true,
    },
  },
  mounted() {
    console.log(this.items);
  },
};
</script>

<style scoped>
.boxs {
  margin: 20px 0 0;
  color: #4472c4;
  color: #2f5597;
  width: 97%;
  margin: 0 auto;
}

.containerfather {
  background-color: #4472c4;
  box-shadow: -1px 2px 6px 2px;
  border-radius: 30px;
  margin-bottom: 35px;
}

.color {
  background-color: #2f5597;
}

.containerChildre {
  height: 50px;
}

.rows {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1,
h2 {
  color: #fff;
  font-size: 15px;
}

.flexs {
  display: flex;
}

.btn {
  margin: 25px 10px;
  height: 45px;
  width: 120px;
  border: none;
  border-radius: 5px;
  background: #f38235;
  color: #fff;
}

.flexs button,
.buttonImg {
  border: none;
  background: none;
}

button img {
  width: 50%;
}

.checks {
  height: 25px;
  width: 25px;
}

.containerfather div:last-child {
  border-radius: 0 0 30px 30px;
}

.checkd {
  width: 23px;
  position: absolute;
  top: 0px;
  right: 99px;
}
</style>
