var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box py-2 my-2 col-md-12",class:("box_" + _vm.index),style:(!_vm.item.read && _vm.item.type == 1
      ? ' border: 2px solid #e0a638;'
      : !_vm.item.read && _vm.item.type == 0
      ? ' border: 2px solid rgb(230, 121, 121);'
      : ''),on:{"mouseenter":function($event){_vm.$store.getters.getUser.profile == 1 ? _vm.enterItem(_vm.index) : ''},"mouseleave":function($event){_vm.$store.getters.getUser.profile == 1 ? _vm.exitItem(_vm.index) : ''},"click":function($event){return _vm.openMensagem(_vm.item.id, _vm.item.read, _vm.index)}}},[_c('div',{staticClass:"row align-items-center",staticStyle:{"min-height":"50px"}},[_c('div',{staticClass:"col-md-2"},[_c('p',{staticClass:"data mb-0",style:(!_vm.item.read && _vm.item.type == 1
            ? 'color: #e0a638;'
            : !_vm.item.read && _vm.item.type == 0
            ? ' color: rgb(230, 121, 121);'
            : '')},[_vm._v(" "+_vm._s(_vm.getDate(_vm.item.creationTime))+" ")])]),_c('div',{staticClass:"col-md-10 cont d-flex align-items-center"},[_c('p',{staticClass:"mb-0 conteudo",class:("conteudo_" + _vm.index)},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]),(
          _vm.$store.getters.getUser.profile == 1 ||
            _vm.$store.getters.getUser.profile == 2
        )?_c('a',{staticClass:"opacity ml-2",class:("opacity_" + _vm.index),attrs:{"hrf":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteNotific(_vm.item.id)}}},[_c('i',{staticClass:"fas fa-times-circle delete"})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }