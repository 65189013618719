<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 px-0 mb-3">
        <ValidationProvider
          class="form__group field"
          tag="div"
          v-slot="{ errors, ariaMsg, classes }"
          name="Titulo"
          rules="required"
        >
          <input
            type="input"
            :class="classes"
            class="form__field"
            placeholder="Titulo"
            name="Titulo"
            id="Titulo"
            @input="$emit('update:title',$event.target.value)"
            :value="title"
          />
          <label for="Titulo" :class="classes" class="form__label">Titulo</label>
        </ValidationProvider>
      </div>
      <ValidationProvider
        class="col-md-12 px-0 mb-3"
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="'required|max:800'"
        name="Descricao"
      >
        <label class="label_default" :class="classes">Comunicado</label>
        <textarea
          class="w-100 px-2 py-2"
          name="Descricao"
          :class="classes"
          :value="description"
          @input="$emit('update:description',$event.target.value)"
        ></textarea>
        <span class="contador" :class="classes" v-bind="ariaMsg">{{description.length }}/ 800</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "description"],
  data() {
    return {};
  }
};
</script>

<style scoped>
.label_default {
  font-size: 14px;
  color: #2474b2;
  font-weight: 500;
}
.input_default {
  height: 45px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}

.form__group {
  margin-top: 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #f4f4f4;
  outline: 0;
  font-size: 14px;
  color: #495057;
  padding-bottom: 4px;
  padding-top: 11px;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 14px;
  cursor: text;
  top: 20px;
}
.fas {
  font-size: 20px;
}
.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  color: #2474b2;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #f4ab7b, #e0a638);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #e0a638;
  font-weight: 700;
}
.failed.form__field ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #dc3545 !important;
  font-weight: 700;
}
textarea.failed {
  border: 1px solid #dc3545;
}
textarea.is-valid {
  border: 1px solid #28a745;
}
.contador.is-valid {
  color: #28a745;
}
textarea.failed:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

textarea.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.failed.form__field {
  border-width: 2px;
  border-image: linear-gradient(to right, #dc3545, #dc3545);
  border-image-slice: 1;
}
.is-valid.form__field {
  border-width: 2px;
  border-image: linear-gradient(to right, #28a745, #28a745);
  border-image-slice: 1;
}
/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
textarea {
  border: 1px solid #e3e3e3;
  height: 80px;
  font-size: 12px;
  color: #495057;
  font-weight: 400;
  outline: none;
}
.contador {
  font-size: 12px;
}
</style>