<template>
  <div
    class="box py-2 my-2 col-md-12"
    :style="
      !item.read && item.type == 1
        ? ' border: 2px solid #e0a638;'
        : !item.read && item.type == 0
        ? ' border: 2px solid rgb(230, 121, 121);'
        : ''
    "
    :class="`box_${index}`"
    @mouseenter="$store.getters.getUser.profile == 1 ? enterItem(index) : ''"
    @mouseleave="$store.getters.getUser.profile == 1 ? exitItem(index) : ''"
    @click="openMensagem(item.id, item.read, index)"
  >
    <div class="row align-items-center" style="min-height:50px;">
      <div class="col-md-2">
        <p
          class="data mb-0"
          :style="
            !item.read && item.type == 1
              ? 'color: #e0a638;'
              : !item.read && item.type == 0
              ? ' color: rgb(230, 121, 121);'
              : ''
          "
        >
          {{ getDate(item.creationTime) }}
        </p>
      </div>
      <div class="col-md-10 cont d-flex align-items-center">
        <p class="mb-0 conteudo" :class="`conteudo_${index}`">
          {{ item.description }}
        </p>
        <!-- <a
          @click.stop="showModal(item.id , item.name)"
          class="opacity mx-3"
          :class="`opacity_${index}`"
          v-if="$store.getters.getUser.profile == 1"
        >
          <i class="fas fa-pen edit"></i>
        </a>-->
        <a
          hrf="#"
          @click.stop="deleteNotific(item.id)"
          class="opacity ml-2"
          :class="`opacity_${index}`"
          v-if="
            $store.getters.getUser.profile == 1 ||
              $store.getters.getUser.profile == 2
          "
        >
          <i class="fas fa-times-circle delete"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ["item", "index"],
  data() {
    return {};
  },
  methods: {
    openMensagem(id, read, index) {
      var content = $(`.conteudo_${index}`);

      if ($(`.conteudo_${index}`).hasClass("open")) {
        $(`.opacity_${index}`).fadeIn(200);
        TweenLite.to(content, 0.2, { height: "13px", whiteSpace: "nowrap" });
        $(`.conteudo_${index}`).removeClass("open");
      } else {
        $(`.opacity`).fadeOut(200);
        setTimeout(() => {
          TweenLite.set(content, {
            width: "100%",
            height: "auto",
            whiteSpace: "normal"
          });
          TweenLite.from(content, 0.2, {
            width: "90%",
            height: "13px",
            whiteSpace: "nowrap"
          });
          $(`.conteudo_${index}`).addClass("open");
        }, 300);
        if (!read && this.$store.getters.getUser.profile != 1) {
          HTTP.post(`services/app/UserAlert/SetAsRead?id=${id}`, "", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          })
            .then(response => {})
            .catch(error => {});
        }
      }
    },
    getDate(val) {
      var data = new Date(val);
      return `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
    },
    enterItem(index) {
      if ($(`.conteudo_${index}`).is(":not(.open)")) {
        var content = $(`.conteudo_${index}`);
        var opacity = $(`.opacity_${index}`);

        setTimeout(() => {
          $(`.opacity_${index}`).fadeIn(200);
        }, 300);
      }
    },
    exitItem(index) {
      if ($(`.conteudo_${index}`).is(":not(.open)")) {
        $(`.opacity`).fadeOut(200);
      }
    },
    deleteNotific(id) {
      HTTP.delete(
        `services/app/Alert/Delete?Id=${id}&&tenantId=${this.$store.getters.tenantRequire.tenantId}`,
        "",
        {
          headers: {
            // Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Alerta Excluido!`
          });
        })
        .catch(error => {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    }
  }
};
</script>
<style scoped>
.opacity {
  display: none;
}
.not_read .data {
  color: #e0a638;
}
.not_read {
  border: 2px solid #e0a638;
}
.box {
  cursor: pointer;
  background-color: #357eb8;
  height: auto;
  overflow: hidden;
  min-height: 50px;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}

.conteudo {
  color: #ffffff;
  white-space: nowrap;
  width: 100%;
  overflow: hidden !important;
  height: 13px;
  text-overflow: ellipsis;
  max-width: 100%;
}
.fas,
.far {
  color: #175d93;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.edit:hover {
  color: #ffffff !important;
}
.delete:hover {
  color: rgb(230, 121, 121) !important;
}
@media screen and (max-width:767px) {
  .box{
    border-radius: 8px;
    background-color: #3F88C1;
  }
}
</style>
